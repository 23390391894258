import React, { Fragment } from 'react';
import { Detail } from 'components/CheckYourDetails/DetailsSection/DetailGroupSection';
import {
  DetailLabel,
  ListItemWithMargins,
  StyledUnorderedList,
  ValueLabel,
} from './styles';

type UnorderedListWithoutBulletsProps = {
  details: (Detail | null)[];
};

const UnorderedListWithoutBullets: React.FC<UnorderedListWithoutBulletsProps> = ({
  details,
}) => (
  <StyledUnorderedList>
    {details.map(
      (detail) =>
        detail && (
          <Fragment key={detail?.label}>
            <ListItemWithMargins>
              {detail?.childBefore}
              <DetailLabel>{detail?.label}</DetailLabel>
              {detail?.isPii ? (
                <ValueLabel data-cs-mask={detail?.isPii}>{detail?.value}</ValueLabel>
              ) : (
                <ValueLabel>{detail?.value}</ValueLabel>
              )}

              {detail?.childAfter}
            </ListItemWithMargins>
          </Fragment>
        )
    )}
  </StyledUnorderedList>
);

export default UnorderedListWithoutBullets;
