import {
  addOrdinalSuffix,
  formatLongDateWithDayFirst,
} from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { graphql, useStaticQuery } from 'gatsby';
import { Detail } from 'components/CheckYourDetails/DetailsSection/DetailGroupSection';
import { createDetail } from 'components/CheckYourDetails/DetailsSection/useCreateDetail';
import {
  combinePetNames,
  petNameReplacer,
} from 'helpers/placeholders/dynamicPetNameHelpers';
import { formatInPoundsWithSign } from 'helpers/poundsHelpers';
import {
  getProductFromProductType,
  getVetFeeLimitFromProduct,
} from 'helpers/productHelpers';
import { getSelectedProductPrices } from 'helpers/useCurrentQuote';
import { ConfirmationQuote } from 'state/quote/confirmationQuote';
import useExcessSection from '../../../components/Confirmation/useExcessSections';

type QuoteDetailsSectionCsProps = {
  csPetConfirmationQuoteDetailsV2: {
    quote_details: {
      cover_start_date_label: string;
      quote_reference_label: string;
      monthly_payment_amount_label: string;
      monthly_payment_day_label: string;
      annual_payment_amount_label: string;
      vet_fees_limit_label: string;
    };
  };
};

export const query = graphql`
  query {
    csPetConfirmationQuoteDetailsV2 {
      quote_details {
        cover_start_date_label
        quote_reference_label
        monthly_payment_amount_label
        monthly_payment_day_label
        annual_payment_amount_label
        vet_fees_limit_label
      }
    }
  }
`;

const useConfirmationDetails = (
  quote: ConfirmationQuote | null,
  isAnnualPayment: boolean | undefined
): (Detail | null)[] => {
  const {
    csPetConfirmationQuoteDetailsV2: { quote_details },
  } = useStaticQuery<QuoteDetailsSectionCsProps>(query);

  const excessSections = useExcessSection();

  if (quote === null) {
    return [];
  }

  const petNames = quote?.petInfos?.map((x) => x.petName);
  const combinedPetNames = combinePetNames(petNames ?? []);
  const coverStartLabel = petNameReplacer(
    combinedPetNames,
    quote_details.cover_start_date_label
  );
  const product = getProductFromProductType(quote?.quoteOptions?.productType);

  const getVetFeeLimitText = (): string => {
    const vetFeeLimit = getVetFeeLimitFromProduct(quote?.quoteOptions?.productType);
    const formattedVetFeeLimit = formatInPoundsWithSign(vetFeeLimit);

    return product ? `Up to ${formattedVetFeeLimit} for each pet, per year` : '';
  };

  const details = [
    createDetail(
      coverStartLabel,
      formatLongDateWithDayFirst(new Date(quote?.policyInfo?.coverStartDate ?? '')),
      true
    ),
    createDetail(quote_details.vet_fees_limit_label, getVetFeeLimitText(), false),
    ...excessSections,
    ...(isAnnualPayment
      ? [
          quote &&
            createDetail(
              quote_details.annual_payment_amount_label,
              formatInPoundsWithSign(getSelectedProductPrices(quote)?.annualPrice.total),
              false
            ),
        ]
      : [
          quote &&
            createDetail(
              quote_details.monthly_payment_amount_label,
              formatInPoundsWithSign(getSelectedProductPrices(quote)?.monthlyPrice.total),
              false
            ),
          createDetail(
            quote_details.monthly_payment_day_label,
            addOrdinalSuffix(quote?.monthlyPaymentDate ?? 0),
            false
          ),
        ]),
  ];

  return details;
};

export default useConfirmationDetails;
