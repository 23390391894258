import React from 'react';
import { Detail } from './DetailGroupSection';

export const createDetail = (
  label: string,
  valueOrUndefined: string | undefined,
  isPii: boolean,
  childBefore?: React.ReactNode,
  childAfter?: React.ReactNode
): Detail => {
  const value = valueOrUndefined ?? '';
  return {
    label,
    value,
    isPii,
    childBefore,
    childAfter,
  };
};
