import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Detail } from 'components/CheckYourDetails/DetailsSection/DetailGroupSection';
import { createDetail } from 'components/CheckYourDetails/DetailsSection/useCreateDetail';
import RichTextWithModal from 'components/RichTextWithModal';
import { petWillBeAgeNineDuringCover } from 'helpers/ageHelpers';
import { getReadablePercentageExcessAmount, PageTitle } from 'helpers/eventTracking';
import { petNameReplacer } from 'helpers/placeholders/dynamicPetNameHelpers';
import { formatStringInPoundsWithSign } from 'helpers/poundsHelpers';
import { useConfirmationQuote } from 'state/quote/confirmationQuote';
import { CoPaymentTextContainer } from '../../pages/pet/confirmation/styles';

type CsExcessSectionProps = {
  csPetConfirmationQuoteDetailsV2: {
    quote_details: {
      fixed_excess_label: string;
      co_payment_contribution_pets_aged_under_9_label: string;
      co_payment_contribution_pets_aged_9_and_over_label: string;
      co_payment_contribution_text: string;
    };
  };
};

const query = graphql`
  query {
    csPetConfirmationQuoteDetailsV2 {
      quote_details {
        fixed_excess_label
        co_payment_contribution_pets_aged_under_9_label
        co_payment_contribution_pets_aged_9_and_over_label
        co_payment_contribution_text
      }
    }
  }
`;

const useExcessSection = (): Detail[] => {
  const {
    csPetConfirmationQuoteDetailsV2: {
      quote_details: {
        fixed_excess_label,
        co_payment_contribution_pets_aged_under_9_label,
        co_payment_contribution_pets_aged_9_and_over_label,
        co_payment_contribution_text,
      },
    },
  } = useStaticQuery<CsExcessSectionProps>(query);
  const [quote] = useConfirmationQuote();
  const petInfos = quote?.petInfos ?? undefined;
  const details: Detail[] = [];
  petInfos?.forEach((petInfo) =>
    details.push(
      createDetail(
        petNameReplacer(petInfo.petName, fixed_excess_label),
        formatStringInPoundsWithSign(petInfo.voluntaryExcessAmount),
        true
      ),
      createDetail(
        quote?.policyInfo &&
          !petWillBeAgeNineDuringCover(petInfo.dob, quote.policyInfo.coverStartDate)
          ? petNameReplacer(
              petInfo.petName,
              co_payment_contribution_pets_aged_under_9_label
            )
          : petNameReplacer(
              petInfo.petName,
              co_payment_contribution_pets_aged_9_and_over_label
            ),
        getReadablePercentageExcessAmount(petInfo.voluntaryExcessPercentage),
        true,
        null,
        quote?.policyInfo &&
          !petWillBeAgeNineDuringCover(petInfo.dob, quote.policyInfo.coverStartDate) && (
            <CoPaymentTextContainer>
              <RichTextWithModal
                data-cy="copayment_text"
                html={co_payment_contribution_text}
                pageTitle={PageTitle.CheckYourDetails}
              />
            </CoPaymentTextContainer>
          )
      )
    )
  );

  if (details === undefined) {
    return [];
  }

  return details;
};

export default useExcessSection;
