import { FormFooterProps as FormFooterComponentProps } from '@rsa-digital/evo-shared-components/components/Form/FormFooter';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import { graphql, useStaticQuery } from 'gatsby';
import React, { MouseEventHandler, PropsWithChildren, RefObject } from 'react';
import { unwrapSingleton } from 'helpers/csTypeProcessors';
import { PageTitle, trackTextButtonClick } from 'helpers/eventTracking';
import { CsIcon } from 'types/contentStack';
import { FormFooterWithTopMargin, StyledButtonLink } from './styles';

type FooterButtonsQuery = {
  csPetGlobalConfig: {
    footer_buttons: {
      back_button: {
        display_text: string;
      };
      save_button: {
        display_text: string;
        icon: [CsIcon];
      };
      download_and_print_button: {
        display_text: string;
        icon: [CsIcon];
      };
      download_quote_button: {
        display_text: string;
        icon: [CsIcon];
      };
    };
  };
};

const footerButtonsQuery = graphql`
  query {
    csPetGlobalConfig {
      footer_buttons {
        back_button {
          display_text
        }
        save_button {
          display_text
          icon {
            icon_code
          }
        }
        download_and_print_button {
          display_text
          icon {
            icon_code
          }
        }
        download_quote_button {
          display_text
          icon {
            icon_code
          }
        }
      }
    }
  }
`;

type FooterButtonProps = {
  onClick?: MouseEventHandler;
  disabled?: boolean;
};

type FormFooterProps = {
  backButton?: FooterButtonProps;
  moveNextButton: FooterButtonProps & {
    text: string;
    href?: string;
    innerRef?: RefObject<HTMLAnchorElement>;
    id?: string;
  };
  saveButton?: FooterButtonProps;
  printButton?: FooterButtonProps;
  downloadButton?: FooterButtonProps;
  contentColumns?: FormFooterComponentProps['contentColumns'];
  pageTitle: PageTitle;
} & ComponentProps;

const FooterButtonLink: React.FC<PropsWithChildren<{
  onClick: MouseEventHandler;
}>> = ({ onClick, children }) => (
  <StyledButtonLink onClick={onClick}>{children}</StyledButtonLink>
);

const FormFooter: React.FC<FormFooterProps> = ({
  backButton,
  moveNextButton,
  saveButton,
  printButton,
  downloadButton,
  contentColumns,
  pageTitle,
  ...rest
}) => {
  const {
    csPetGlobalConfig: {
      footer_buttons: {
        back_button,
        save_button,
        download_and_print_button,
        download_quote_button,
      },
    },
  } = useStaticQuery<FooterButtonsQuery>(footerButtonsQuery);

  const backButtonProps = backButton
    ? {
        onClick: backButton.onClick,
        disabled: backButton.disabled,
        buttonText: back_button.display_text,
      }
    : undefined;

  const printIcon = unwrapSingleton(download_and_print_button.icon);
  const downloadIcon = unwrapSingleton(download_quote_button.icon);
  const saveIcon = unwrapSingleton(save_button.icon);

  /* istanbul ignore if */
  if (!printIcon || !saveIcon || !downloadIcon) {
    throw new Error('Icons for form footer missing!');
  }

  return (
    <FormFooterWithTopMargin
      contentColumns={contentColumns}
      nextButton={{
        onClick: moveNextButton.onClick,
        buttonText: moveNextButton.text,
        href: moveNextButton.href,
        type: 'submit',
        disabled: moveNextButton.disabled,
        innerRef: moveNextButton.innerRef,
        id: moveNextButton.id,
      }}
      backButton={backButtonProps}
      {...componentProps(rest)}>
      {(printButton || saveButton || downloadButton) && (
        <>
          {/* The save button should only be displayed when a valid cover is selected */}
          {saveButton && (
            <FooterButtonLink
              onClick={(e) => {
                if (saveButton.onClick) {
                  trackTextButtonClick(pageTitle, save_button.display_text);
                  saveButton.onClick(e);
                }
              }}>
              {save_button.display_text}
            </FooterButtonLink>
          )}
          {/* The print button should only be displayed when a valid cover is selected */}
          {printButton && (
            <FooterButtonLink
              onClick={(e) => {
                if (printButton.onClick) {
                  trackTextButtonClick(pageTitle, download_and_print_button.display_text);
                  printButton.onClick(e);
                }
              }}>
              {download_and_print_button.display_text}
            </FooterButtonLink>
          )}
          {downloadButton && (
            <FooterButtonLink
              onClick={(e) => {
                if (downloadButton.onClick) {
                  trackTextButtonClick(pageTitle, download_quote_button.display_text);
                  downloadButton.onClick(e);
                }
              }}>
              {download_quote_button.display_text}
            </FooterButtonLink>
          )}
        </>
      )}
    </FormFooterWithTopMargin>
  );
};

export default FormFooter;
