import Icon from '@rsa-digital/evo-shared-components/components/Icon';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import React, { MouseEventHandler } from 'react';
import { IconWrapper, StyledLink } from './styles';

type PolicyLinkProps = {
  icon?: string;
  url: string;
  text: string;
  screenreaderText?: string;
  openInNewTab: boolean;
  onClick: MouseEventHandler;
} & ComponentProps;

const PolicyLink: React.FC<PolicyLinkProps> = ({
  icon,
  url,
  text,
  screenreaderText,
  openInNewTab,
  onClick,
  ...props
}) => (
  <StyledLink
    {...componentProps(props)}
    href={url}
    onClick={onClick}
    openInNewTab={openInNewTab}
    color={colors.core01}
    aria-label={screenreaderText}
    removeVisitedStyle>
    {icon && (
      <IconWrapper>
        <Icon name={icon} size="small" />
      </IconWrapper>
    )}
    {text}
  </StyledLink>
);

export default PolicyLink;
