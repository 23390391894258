import Image from '@rsa-digital/evo-shared-components/components/Image';
import SimplePanel from '@rsa-digital/evo-shared-components/components/Panel/SimplePanel';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const DiscountPanelContainer = styled(SimplePanel)`
  margin-bottom: ${spacing(1)};
  margin-top: ${spacing(1)};
  display: flex;
  flex-direction: row;
`;
export const SizedImage = styled(Image)`
  flex-shrink: 0;
  width: ${spacing(6)};
  max-height: ${spacing(6)};

  margin-right: ${spacing(3)};
  margin-bottom: ${spacing(3)};

  ${mediaQuery.tabletPortrait`
    margin-bottom: 0;
  `};
`;
