import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { colors } from '@rsa-digital/evo-shared-components/theme/JohnLewis/colors';
import { heavyFontWeight } from '@rsa-digital/evo-shared-components/theme/JohnLewis/fonts';
import styled, { css } from 'styled-components';
import PromotionalBox from 'components/PromotionalBox';
import RichTextWithModal from 'components/RichTextWithModal';

export const DocumentsDispatchingDetails = styled(RichTextWithModal)`
  ${RichText} {
    p {
      ${mediaQuery.tabletLandscape`
        ${fonts.paragraphLarge}
      `}
    }
  }
  margin-bottom: ${spacing(4)};
`;

export const ConfirmationQuoteDetailsContainer = styled.div`
  margin: ${spacing(2)} 0 ${spacing(6)};
`;

export const QuoteReferenceWrapper = styled.div`
  position: relative;
  width: 100%;
  align-items: left;
  margin-top: ${spacing(6)};

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(8)};
  `}
`;

const quoteReferenceFonts = css`
  ${fonts.paragraph}

  ${mediaQuery.tabletLandscape`
    ${fonts.paragraphLarge}
  `}
`;

export const QuoteReferenceLabel = styled.span`
  ${quoteReferenceFonts}
`;

export const QuoteReferenceValue = styled.span`
  ${quoteReferenceFonts}

  & {
    font-weight: ${heavyFontWeight};
  }
`;

export const CoPaymentTextContainer = styled.div`
  margin-top: ${spacing(1)};
  width: 100%;
`;

export const ThankYouMessage = styled.p`
  ${fonts.headingSmall}
  margin-top: ${spacing(4)};

  ${mediaQuery.tabletPortrait`
    text-align: center;
    margin-top: ${spacing(2)};
  `}

  && {
    font-weight: ${heavyFontWeight};
    margin-bottom: 0;
  }
`;

export const ConfirmationOfferPanel = styled(PromotionalBox)`
  background-color: ${colors.notificationInfoLight};

  && {
    border: none;
    margin-top: 0;

    ${mediaQuery.tabletPortrait`
    padding-right: ${spacing(1)};
  `}

    ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(1)};
  `}
  }
`;

export const StyledGridItem = styled(GridItem)`
  margin-top: ${spacing(2)};

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(4)};
  `}
`;
