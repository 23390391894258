import FormFooter from '@rsa-digital/evo-shared-components/components/Form/FormFooter';
import {
  ChildrenGridItem,
  InnerContainer,
} from '@rsa-digital/evo-shared-components/components/Form/FormFooter/styles';
import { Grid } from '@rsa-digital/evo-shared-components/components/Grid';
import { ButtonLink } from '@rsa-digital/evo-shared-components/components/Link';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { colors } from '@rsa-digital/evo-shared-components/theme/JohnLewis/colors';
import styled from 'styled-components';

export const FormFooterWithTopMargin = styled(FormFooter)`
  margin-top: ${spacing(4)};
  ${mediaQuery.tabletPortrait`
    margin-top: ${spacing(8)};
  `}

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(4)};
  `}

  ${InnerContainer} {
    padding-bottom: ${spacing(5)};

    ${mediaQuery.tabletLandscape`
      padding-bottom: ${spacing(4)};
    `}

    ${Grid} > {
      ${mediaQuery.tabletLandscape`
        :first-child {
         width: auto;
        }
     `}
     ${ChildrenGridItem} {
        ${mediaQuery.tabletLandscape`
          margin-bottom :1px;
          align-items: end;
        `}     
     }
    }
  }
`;

export const StyledButtonLink = styled(ButtonLink)`
  margin: 0;
  box-shadow: inset 0 0 0 1px ${colors.core01};
  padding: ${spacing(1)} ${spacing(3)};
  &&&& {
    text-decoration: none;
  }
  &:hover {
    box-shadow: inset 0 0 0 2px ${colors.core01};  
  }
  :not(:last-child) {
    margin-bottom: ${spacing(4)};
  }

  ${mediaQuery.tabletPortrait`
    :not(:last-child) {
      margin-bottom: 0;
      margin-right: ${spacing(3)};
    }
  `}

  ${mediaQuery.tabletLandscape`
    && {
      margin-left: ${spacing(2)};
      margin-right: 0;
    }
    
    :first-child {
      margin-left:0;
    }
  `}

  ${mediaQuery.desktop`
    && {
      ${fonts.headingXSmall}
    }
  `}
`;
